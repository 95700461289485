@import "./styles.css";

html,
body,
head {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  /* overflow-x: hidden; */
}

element {
  --phthalo-green: #14281dff;
  --hunter-green: #355834ff;
  --antique-bronze: #6e633dff;
  --light-french-beige: #c2a878ff;
  --baby-powder: #f1f5f2ff;
}

@media (max-width: 800px) {
  html,
  body {
    overflow-x: hidden;
  }
}

.App {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.AppBar {
  height: 105px;
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* max-width: 1500px; */
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.Footer {
  background-color: black;
  padding: 10px 10px 0px 10px;
  /* position: fixed; */
  /* font-weight: bold; */
  bottom: 0;
  width: 100%;
  height: 2.5rem;
  text-align: center;
  margin-top: 14px;
}

.logo {
  margin-left: 25px;
  font-size: 25px;
  margin-right: auto;
  font-weight: bolder;
  display: inline-block;
  flex: 1;
}

.menu {
  flex: 2;
  height: 100%;
  display: flex;
  gap: 25px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  list-style: none;
  text-decoration: none;
  font-family: "Noto Sans", sans-serif;
  font-size: 16px;
  margin-right: 40px;
  font-weight: bold;
}

.HomeContainer {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: sticky;
}

.HomeContainer:hover {
  filter: brightness(120%);
}

a {
  color: black;
}

a:hover {
  color: #01bf71;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  position: sticky;
}

.bars {
  flex: 1;
  font-size: 28px;
  align-items: center;
  justify-content: right;
  display: flex;
}

/*  Social Icons  */

.SocialIconsContainer {
  display: flex;
  gap: 15px;
  margin-right: 25px;
  margin-left: auto;
  align-items: center;
  margin-top: 10px;
}

.icon-facebook,
.icon-linkedin,
.icon-phone {
  height: 30px;
  width: 30px;
}

.icon-facebook:hover,
.icon-linkedin:hover,
.icon-phone:hover {
  color: #01bf71;
}

.icon-bars {
  margin-right: 25px;
}

.icon-bars:hover {
  color: #01bf71;
  transition: 0.2s ease-in-out;
  cursor: pointer;
}

/*  About Us */

.AboutUsContainer {
  max-width: 1500px;
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center;
}

.AboutUsTagline {
  text-align: center;
  font-family: "Gochi Hand", cursive;
}

.contact-tagline {
  font-family: "Poppins", sans-serif;
}

.AboutUsBody {
  text-align: left;
  margin-top: 35px;
  margin-bottom: 250px;
  font-size: 18px;
}

.AboutUsContentContainer {
  display: flex;
  justify-content: center;
  gap: 50px;
  width: 100%;
  margin-top: 25px;
  font-weight: light;
  /* margin-bottom: 25px; */
  position: relative;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center;
}

.AboutUsBanner {
  margin-right: auto;
  margin-left: auto;
  display: block;
}

.BannerContainer {
  width: 100%;
  margin-bottom: 25px;
}

.AboutUsMenuBtn {
  margin-top: 40px;
  margin-right: 15px;
  background-color: #01bf71;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: none;
  font-size: 16px;
  padding: 12px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  -webkit-transition-duration: 0.3s;
}

.AboutUsEventMenuBtn {
  margin-top: 15px;
  margin-right: 15px;
  background-color: #01bf71;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: none;
  font-size: 16px;
  padding: 12px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  -webkit-transition-duration: 0.3s;
}

.AboutUsMenuBtn:hover,
.AboutUsEventMenuBtn:hover {
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.5);
  -webkit-transform: scale(1.1);
}

.contactContainer {
  height: 500px;
  background-color: #01bf71;
  transform: translateY(-25px);
  font-family: "Noto Sans", sans-serif;
  width: 100%;
}

.gap-filler {
  background-color: #01bf71;
  height: 45px;
}

.contactFormContainer {
  display: flex;
  background-color: #01bf71;
  gap: 20px;
  align-items: center;
  justify-content: center;
  padding: 15px;
}

.EventsBodyContainer {
  text-align: left;
}

.navLink {
  text-decoration: none;
  color: black;
}

/* Cafe Menu */

.CafeMenuContainer {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 1.5em;
}

.CafeMenuMenu {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  margin-bottom: 100px;
}

.CafeMenuGroupHeading {
  margin: 0;
  padding-bottom: 1em;
  padding-top: 1em;
  border-bottom: 3px solid #01bf71;
  margin-top: 30px;
}

.CafeMenuGroup {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1em;
  padding: 1.5em 0;
  margin-bottom: 30px;
}

.CafeMenuItem {
  display: flex;
}

.CafeMenuItemText {
  flex-grow: 1;
  margin-right: 2em;
}

.CafeMenuItemHeading {
  margin-bottom: 0.6em;
  display: flex;
  justify-content: space-between;
}

.CafeMenuPDFMessage {
  text-align: center;
  margin-top: 30px;
}

.SectionContainer {
  display: flex;
  flex-direction: column;
  padding-bottom: 0px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 10%);
  max-width: 400px;
  overflow: hidden;
  border-radius: 5px;
  height: 100%;
  border: 0.5px solid gainsboro;
  margin-top: auto;
  margin-bottom: auto;
}

.SectionTitle {
  margin-top: 5px;
  margin-bottom: 10px;
}

.SectionInfoContainer {
  padding: 15px;
  max-inline-size: 400px;
}
.InfoBtnSection {
  border-top: 1px solid gainsboro;
  margin-bottom: 0;
  background-color: whitesmoke;
  padding: 15px;
  height: 100%;
}
.SectionBtn {
  color: white;
  border: none;
  border-radius: 5px;
  padding: 13px;
  box-shadow: 0 0 15px rgb(0 0 0 / 10%);
  cursor: pointer;
}
.SectionBtn:hover {
  transform: scale(1.1);
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.5);
}
@media screen and (min-width: 992px) {
  .CafeMenu {
    font-size: 18px;
  }

  .CafeMenuGroup {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Our Team */

.OurTeam {
  max-width: 1500px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 35px;
  /* border-top: 1.5px solid black; */
  text-align: center;
  align-items: center;
  margin-bottom: 60px;
}

.OurTeamContainer {
  position: relative;
  background-color: whitesmoke;
  transform: translateY(-24px);
  width: 100%;
}

.OurTeamTagline {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 20px;
}

.OurTeamInfo {
  display: flex;
  gap: 50px;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 200px;
  margin-top: 25px;
  background-color: whitesmoke;
}

.TeamInfoHeader {
  margin: 0px;
  padding: 0px;
  font-weight: bold;
}

.TeamInfoDesc {
  margin: 0px;
  padding: 0px;
}

.TeamInfoAvatar {
  height: 240px;
  width: 222px;
  margin-bottom: 15px;
  border: 2px solid black;
}

.InfoContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 5px;
  background-color: #01bf71;
  padding: 50px;
  border-radius: 10px;
}

.InfoContainer:hover {
  transform: scale(1.1);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

menu.Link:hover {
  border-bottom: 1px solid black;
}

/*  Tabs   */

.TabContainer {
  display: flex;
  height: 100%;
  margin-right: auto;
  margin-left: auto;
  flex-direction: column;
  position: relative;
  background: #f1f1f1;
  /* margin: 100px auto 0; */
  word-break: break-all;
  border: 1px solid rgba(0, 0, 0, 0.274);
}

.BlockTabs {
  display: flex;
}
.tabs {
  padding: 15px;
  text-align: center;
  width: 50%;
  background: rgba(128, 128, 128, 0.075);
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.274);
  box-sizing: content-box;
  position: relative;
  outline: none;
}
.tabs:not(:last-child) {
  border-right: 1px solid rgba(0, 0, 0, 0.274);
}

.active-tab {
  background: white;
  border-bottom: 1px solid transparent;
}

.active-tab::before {
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 2px);
  height: 5px;
  background: #01bf71;
}

.content-tabs {
  flex-grow: 1;
}
.content {
  background: white;
  padding: 20px;
  width: 100%;
  height: 100%;
  display: none;
}
.content h2 {
  padding: 0px 0 5px 0px;
}
.content hr {
  width: 100px;
  height: 2px;
  background: #222;
  margin-bottom: 5px;
}
.content p {
  width: 100%;
  height: 100%;
}
.active-content {
  display: inline-block;
}

::before,
::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.EventsInfoBody {
  text-align: center;
}

.EventsContainer {
  font-family: "Poppins", sans-serif;
  margin-left: auto;
  margin-right: auto;
  padding: 30px;
  display: flex;
  justify-content: center;
  gap: 50px;
  position: relative;
}

.EventsMasterContainer {
  font-family: "Poppins", sans-serif;
}
.EventsPdfButton {
  margin-left: auto;
  align-items: center;
}

.EventsBrunchInfo {
  max-width: 1500px;
  padding: 25px;
}

.EventsLink {
  height: 30px;
  font-size: 24px;
}

.LinkContainer {
  margin-top: 10px;
}
.LinkArrow {
  height: 30px;
  width: 30px;
  transform: translateY(7px);
  font-size: 24px;
}

.QuicheTeaContiner {
  display: flex;
  flex-direction: column;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  gap: 25px;
  padding: 15px;
  margin-bottom: 30px;
  text-align: center;
}

.QuicheFrittataContainer {
  text-align: left;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.TeaContainer {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin-bottom: 25px;
  margin-top: 25px;
}

.QuicheList {
  padding: 0px;
  text-align: left;
}
.TeaList {
  padding: 0px;
}

.QuicheImgContainer {
  display: flex;
  flex-direction: row;
  gap: 50px;
  margin-left: auto;
  margin-right: auto;
  padding: 15px;
}

.BrunchAddOns {
  display: flex;
  flex-direction: column;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  gap: 25px;
  padding: 15px;
  margin-bottom: 30px;
  text-align: center;
}

.RowFlex {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  margin-bottom: 25px;
  margin-top: 40px;
  /* margin-right: 50px; */
}

.OptionsContainer {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.OptionsList {
  padding: 0px;
  text-align: left;
}

.AddOnHeading {
  margin-bottom: 0.6em;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}
.AddOnDesc {
  text-align: left;
  display: block;
}
.AddOnContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  margin-right: 2em;
}

.EmailFormContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 500px;
  max-width: 800px;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
}

.EmailFormInput {
  font-family: "Noto Sans", sans-serif;
  margin: 4px 0;
  padding: 8px;
  border-radius: 12px;
  width: 399px;
}

.EmailFormBtn {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  background-color: whitesmoke;
  margin-left: 50px;
  margin-right: 375px;
  border: none;
  padding: 8px;
  width: 80px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  -webkit-transition-duration: 0.3s;
}

.EmailFormBtn:hover,
.EmailFormBtn:active,
.EmailFormBtn:focus {
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.5);
  -webkit-transform: scale(1.1);
  background-color: whitesmoke;
}

::placeholder {
  font-family: "Noto Sans", sans-serif;
}
.EmailFormContainer {
  margin: 0px;
}
.custom-shape-divider-bottom-1672709321 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
  background-color: whitesmoke;
}

.custom-shape-divider-bottom-1672709321 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 150px;
}

.custom-shape-divider-bottom-1672709321 .shape-fill {
  fill: #01bf71;
}

.curveContainer {
  position: relative;
  margin-top: 200px;
}

.custom-shape-divider-bottom-1672838994 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1672838994 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 150px;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1672838994 .shape-fill {
  fill: #f5f5f5;
}

.custom-shape-divider-bottom-1673187484 {
  /* position: absolute; */
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
}

.custom-shape-divider-bottom-1673187484 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 150px;
}

.custom-shape-divider-bottom-1673187484 .shape-fill {
  fill: #01bf71;
}

.MobileSlider {
  transform: translateY(-200%);
  display: none;
}

.MobileSliderDisplay {
  background-color: #01bf71;
  top: -100;
  transform: translateY(0px);
  transition: all 0.5s ease-in-out;
}

.MobileMenu {
  background-color: #01bf71;
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 10px;
  font-family: "Noto Sans", sans-serif;
  font-size: 18px;
  text-align: center;
}

.MobileLink {
  color: white;
  font-size: 24px;
  text-decoration: none;
}

.MobileOverlay {
  height: 0%;
  width: 100%;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.95);
  overflow-x: hidden;
  overflow-y: hidden;
  transition: 0.5s;
}

.MobileOverlayContent {
  position: relative;
  top: 25%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 35px;
}

.MobileOverlayCloseBtn {
  color: white;
  position: absolute;
  top: 20px;
  right: 35px;
  font-size: 30px;
}
.MobileOverlayCloseBtn:hover {
  color: #01bf71;
  transition: 0.3s ease-in-out;
}

.OurInfoContainer {
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: flex-start;
  border-left: 1px solid black;
  padding: 40px;
  background-color: #f5f5f5;
  box-shadow: 5px 0px 5px black;
  border-radius: 5px;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  width: 275px;
}

.AddressInfoContainer {
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.InfoIcon {
  font-size: 18px;
}

.ContactFormLabel {
  margin-right: auto;
  margin-left: 50px;
  font-weight: bold;
}

.EventsImage {
  width: 650px;
  height: 650px;
  box-shadow: 1px 0px 1px gray;
}

.BannerImg {
  height: "400px";
  width: "100%";
  align-self: "center";
  background-repeat: "no-repeat";
  background-size: "100% 100%";
  top: "0";
  left: "0";
  background-position: "center";
}

.InfoBtnContainer {
  background-color: #01bf71;
  width: 120px;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  align-content: center;
  justify-content: center;
}

.InfoBtnLink {
  text-decoration: none;
  padding: 5px;
}

.InfoBtnLink:hover {
  color: black;
}

.about-events-info {
  margin-top: 5px;
  margin-bottom: 150px;
  display: flex;
  gap: 35px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 900px) {
  .AboutUsImage {
    display: none;
  }

  .AppBar {
    width: 100%;
  }

  .SectionContainer {
    width: 355px;
  }

  .about-events-info {
    margin-top: 25px;
    margin-bottom: 150px;
    display: flex;
    gap: 25px;
    flex-direction: column;
  }

  .MobileOverlayContent {
    max-width: 400px;
  }

  .BannerImg {
    height: 250px;
  }
  .EventsImage {
    width: 375px;
    height: 375px;
    margin-left: auto;
    margin-right: auto;
    display: none;
  }

  .OurTeamInfo {
    display: flex;
    gap: 35px;
  }

  .EmailFormInput {
    width: 299px;
    font-family: Poppins;
  }

  .EmailFormBtn {
    margin-right: 275px;
  }

  .AboutUsBody {
    margin-bottom: 130px;
    z-index: 1;
  }
  .contactFormContainer {
    flex-direction: column;
    gap: 50px;
  }
  .EventsContainer {
    flex-direction: column;
  }
  .menu {
    display: none;
  }
  .icon-facebook,
  .icon-linkedin,
  .icon-phone {
    display: none;
    visibility: hidden;
  }
  .ImageSliderContainer {
    display: none;
  }
  .icon-bars {
    margin-right: 15px;
    font-size: 20px;
    visibility: visible;
  }
  .bars {
    display: flex;
    justify-content: right;
  }
  .logo {
    font-size: 15px;
    margin-left: 15px;
  }
  .AboutUsTagline {
    font-size: 24px;
  }
  .AboutUsBody {
    font-size: 12px;
    text-align: center;
    margin-top: 5px;
  }
  .AboutUsContainer {
    width: 100%;
    height: 100%;
  }
  .AboutUsContentContainer {
    margin-top: 0px;
    flex-direction: column;
    width: 100%;
  }
  .OurTeamInfo {
    flex-direction: column;
    margin-top: 15px;
  }
  .OurTeamTagline {
    text-align: center;
    width: 100%;
    justify-content: center;
    align-items: middle;
  }
}
